<template>
  <div
    ref="target"
    :style="backgroundStyle"
    class="container2 relative"
    v-html="formattedContent"
  />
</template>

<script setup lang="ts">
import { RichTextBackgroundTypes, RichTextModuleData } from "ecom-types";

const props = withDefaults(
  defineProps<{
    data: RichTextModuleData;
    blockIndex?: number;
  }>(),
  {
    blockIndex: -1,
  },
);
const target = ref(null);

const backgroundStyle = computed(() => {
  switch (props.data.background_type) {
    case RichTextBackgroundTypes.COLOR: {
      return {
        backgroundColor:
          props.data.color.hexa || props.data.color || "transparent",
      };
    }
    case RichTextBackgroundTypes.CUSTOM: {
      return {
        background: props.data.custom_background,
      };
    }
    default: {
      return {};
    }
  }
});

const formattedContent = computed(() => {
  // Define a regular expression to match script tags with a function
  const scriptTagRegex = /<script\b[^>]*>*<\/script>/gi;
  // Use the replace method to remove script tags with functions
  const modifiedHtmlString = props.data.content.replace(scriptTagRegex, "");
  return modifiedHtmlString;
});

onMounted(() => {
  const scripts = extractDataFromScriptTags(props.data.content);

  scripts.forEach((script) => {
    addScripts(script, target.value, "end");
  });
});
</script>
